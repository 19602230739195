<template>
  <div class="todo_list">
    <div class="todo_content">
      <div class="job_box todo_box">
        <div class="title">Todo</div>
        <transition-group name="todo-list" tag="ul">
          <li class="todo_item" v-for="(item, index) in todo" :key="item" @click="deleteTodo(item, index)">
            {{index + 1}} 、 {{item}}
          </li>
        </transition-group>
      </div>
      <div class="job_box done_box">
        <div class="title">Done</div>
        <transition-group name="done-list" tag="ul">
          <li class="done_item" v-for="(item, index) in done" :key="item" @click="deleteDone(item, index)">
            {{index + 1}} 、 {{item}}
          </li>
        </transition-group>
      </div>
    </div>
    <div class="tip_box">
      做完👆todo，你就是今天
      <div>
        ✨最靓的仔✨
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'todoList',
  data () {
    return {
      todo: ['读书1h', '出声朗读1h', '日记', '学习至少.5h', '练习气息20m', '平板支撑2m', '短记练习20m'],
      done: [],
      today: '',
      localDate: ''
    }
  },
  mounted () {
    const date = new Date()
    this.today = date.getFullYear() + '' + date.getMonth() + '' + date.getDate()
    this.localDate = localStorage.getItem('localDate') || this.today
    console.log('------', localStorage.getItem('todo'))
    if (this.today == this.localDate) {
      this.todo = JSON.parse(localStorage.getItem('todo')) || this.todo
      this.done = JSON.parse(localStorage.getItem('done')) || this.done
    } else {
      this.todo = ['读书1h', '出声朗读1h', '日记', '学习至少.5h', '练习气息20m', '平板支撑2m', '短记练习20m']
      this.done = []
    }
  },
  methods: {
    deleteTodo: _.throttle(function (val, index) {
      this.todo.splice(index, 1)
      this.done.push(val)
      console.log('点击了todo----', this.todo)
      this.saveLocal()
    }, 1000, { 'trailing': false }),
    deleteDone: _.throttle(function (val, index) {
      console.log('点击了done----')
      this.done.splice(index, 1)
      this.todo.push(val)
      this.saveLocal()
    }, 1000, { 'trailing': false }),
    saveLocal () {
      localStorage.setItem('todo', JSON.stringify(this.todo))
      localStorage.setItem('done', JSON.stringify(this.done))
      const date = new Date()
      localStorage.setItem('localDate', date.getFullYear() + '' + date.getMonth() + '' + date.getDate())
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>